<template>
  <div
    ref="mdtTable"
    class="mdt-table table-content"
    :class="`${$route.name}-${progressState}`">
    <TableBulkOptions
      v-if="isBulkOptionsEnabled"
      :bulk-options="table.bulkOptions"
      :bulk-extra-options="table.bulkExtraOptions"
      :settings="table.settings"
      :selected-rows="selectedRows"
      :create-new="table.createNew"
      :excel-download="table.excelDownload"
      :random-people-viewing="table.randomPeopleViewing"
      :random-applicants-viewing="table.randomApplicantsViewing"
      :legend="table.legend"
      :table-id="tableId"
      @openReorder="isReorderOpen = true"
      @bulkOptionSelect="$emit('bulkOptionSelect', {
        item: $event,
        selectedRows,
      })"
      @bulkExtraOptionSelect="$emit('bulkExtraOptionSelect', {
        item: $event,
        selectedRows,
      })"
      @createNew="$emit('createNew', $event)"
      @openRandomPeopleViewing="$emit('openRandomPeopleViewing')"
      @openRandomApplicantsViewing="$emit('openRandomApplicantsViewing')" />
    <TableTitle
      v-if="table.title"
      :title="table.title" />
    <div
      v-if="!stickyHeader && displayScrollArrows"
      ref="arrowScroll"
      :style="scrollArrowsPositionHelper"
      class="scroll-buttons-container">
      <i
        :class="{ 'scroll-arrow-disabled': scrollLeftArrow > 0 }"
        class="fa-solid fa-circle-chevron-left"
        @click="() => scrollOnArrow('left')" />
      <i
        :class="{ 'scroll-arrow-disabled': scrollRightArrow > 0 }"
        class="fa-solid fa-circle-chevron-right"
        @click="() => scrollOnArrow('right')" />
    </div>
    <vue-scroll
      ref="vue-scroll"
      :ops="vueScrollOptions"
      @handle-scroll="onTableScroll">
      <div
        v-if="stickyHeader && displayScrollArrows"
        ref="arrowScrollSticky"
        :style="scrollArrowsStickyPosition"
        class="scroll-buttons-container-sticky">
        <i
          :class="{ 'scroll-arrow-disabled': scrollLeftArrow > 0 }"
          class="fa-solid fa-circle-chevron-left"
          @click="() => scrollOnArrow('left')" />
        <i
          :class="{ 'scroll-arrow-disabled': scrollRightArrow > 0 }"
          class="fa-solid fa-circle-chevron-right"
          @click="() => scrollOnArrow('right')" />
      </div>
      <div
        v-if="table.tableData.header.length"
        class="table-wrapper">
        <div
          v-if="stickyHeaders && !table.loading && stickyHeader"
          class="table-fixed-headers">
          <table class="main-table headers">
            <thead>
              <tr>
                <TableTh
                  v-for="(th, i) in table.tableData.header"
                  :key="i"
                  :th="th"
                  :selected-rows="selectedRows"
                  :max-selected="maxSelected"
                  :table-body="table.tableData.body"
                  :sort="sort"
                  :text-centered-columns="textCenteredColumns"
                  @sort="sortColumn" />
              </tr>
            </thead>
          </table>
        </div>
        <ul
          v-if="table.loading"
          class="placeholders-list">
          <content-placeholders
            v-for="(index) in 7"
            :key="index"
            class="placeholders-list-item">
            <content-placeholders-heading />
            <content-placeholders-text :lines="4" />
          </content-placeholders>
        </ul>
        <table
          v-else
          class="main-table">
          <thead>
            <tr>
              <TableTh
                v-for="(th, i) in table.tableData.header"
                :key="i"
                :th="th"
                :selected-rows="selectedRows"
                :max-selected="maxSelected"
                :table-body="table.tableData.body"
                :sort="sort"
                :text-centered-columns="textCenteredColumns"
                @sort="sortColumn" />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, r) in table.tableData.body"
              ref="tr"
              :key="r"
              v-tooltip.bottom-start="inactiveRow(row).tooltip"
              :data-row-id="row._id"
              :class="[{
                         selected: isRowSelected(row),
                         inactive: inactiveRow(row).disabled,
                       },
                       row._type,
              ]"
              @mouseenter="rowMouseEnter"
              @mouseleave="rowMouseLeave">
              <TableTdHeader
                v-if="row._header || row._subheader"
                :header="row._header || row._subheader"
                :subheader="!!row._subheader"
                :header-columns="table.tableData.header.length" />
              <template v-else>
                <TableTd
                  v-for="th in table.tableData.header"
                  :key="th.key"
                  :ref="`tableTd-${r + 1}`"
                  :row="row"
                  :th="th"
                  :selected-rows="selectedRows"
                  :max-selected="maxSelected"
                  :editable="editable"
                  :text-centered-columns="textCenteredColumns"
                  @setFavorite="$emit('setFavorite', row)"
                  @openSidepanel="$emit('openSidepanel', $event)"
                  @selectDetailOption="$emit('selectDetailOption', $event)"
                  @toggleButton="$emit('toggleButton', $event)"
                  @reservation="$emit('reservation', $event)"
                  @modalClosed="rowMouseLeave({ target: $refs.tr[r] })"
                  @tableTdButtonSelect="$emit('tableTdButtonSelect', $event);
                                        rowMouseLeave({ target: $refs.tr[r] })" />
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </vue-scroll>
    <div
      v-if="isTableEmpty"
      class="table-empty flex-center">
      <img src="@/assets/images/nothing-found.png">
      <span>
        {{ 'admin_no_data_found' | translate }}
      </span>
    </div>
    <TablePagination
      v-if="table.tableData.header.length
        && table.pagination && table.pagination.enabled
        && table.pagination.totalItems > 0"
      :current-page-actual="paginationCurrentPageActual"
      :total="table.pagination.totalItems"
      :page-size="table.pagination.rowsPerPage"
      :table="table"
      class="pagination"
      @currentPage="onPaginationCurrentPage"
      @size="onPaginationPageSize" />
    <MdtReorder
      v-if="isReorderOpen"
      :title="table.settings.tooltip"
      :items="table.settings.columns"
      :ordered-keys="table.settings.columnsOrder"
      @close="isReorderOpen = false"
      @saveReorderSettings="saveReorderSettings" />
  </div>
</template>

<script>
import {
  SCROLL_ARROWS_RIGHT_POSITION_80,
  SCROLL_ARROWS_TOP_POSITION_70,
  SCROLL_ARROWS_TOP_POSITION_77,
  SCROLL_ARROWS_TOP_POSITION_15,
  TABLE_SCROLL_ARROW_LENGTH,
} from '@/utility/constants';
import TableBulkOptions from './TableBulkOptions.vue';
import TableTh from './TableTh.vue';
import TableTd from './TableTd.vue';
import TablePagination from './TablePagination.vue';
import TableTdHeader from './TableTdHeader.vue';
import TableTitle from './TableTitle.vue';

export default {
  name: 'MdtTable',
  components: {
    TableBulkOptions,
    TableTh,
    TableTd,
    TablePagination,
    TableTdHeader,
    TableTitle,
  },
  props: {
    table: {
      type: Object,
      required: true,
    },
    stickyHeaders: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    maxSelected: {
      type: Number,
      default: undefined,
    },
    displayScrollArrows: {
      type: Boolean,
      default: true,
    },
    scrollArrowsPosition: {
      type: Object,
      default: () => ({
        top: SCROLL_ARROWS_TOP_POSITION_70,
        right: 0,
      }),
    },
    scrollArrowsStickyPosition: {
      type: Object,
      default: () => ({
        top: SCROLL_ARROWS_TOP_POSITION_77,
        right: SCROLL_ARROWS_RIGHT_POSITION_80,
      }),
    },
    tableId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableEl: null,
      isReorderOpen: false,
      selectedRows: [],
      preventUpdateSelectedRows: undefined,
      sort: {
        key: '',
        order: '',
      },
      progressState: '',
      mdtTableRef: null,
      stickyHeader: false,
      tableScroll: {
        top: 0,
        left: 0,
        leftToRight: true,
      },
      vueScrollOptions: {
        bar: {
          keepShow: true,
        },
      },
      textCenteredColumns: [
        'detailColumn',
        'overall_evaluation',
        'num_applications',
        'num_interested_applications',
        'application_url',
        'reservation',
        'homegate',
        'newhome',
        'immoscout',
        'icasa',
        'flatfox',
        'email_validation',
      ],
      scrollLeft: 0,
      scrollRight: 1,
    };
  },
  computed: {
    scrollArrowsPositionHelper() {
      if (!this.isBulkOptionsEnabled) {
        return {
          top: SCROLL_ARROWS_TOP_POSITION_15,
        };
      }
      return this.scrollArrowsPosition;
    },
    isBulkOptionsEnabled() {
      return Boolean(this.table?.bulkExtraOptions?.length
        || this.table?.bulkOptions?.length
        || this.table?.settings?.enabled
        || this.table?.createNew?.enabled);
    },
    isTableEmpty() {
      return this.table.tableData.header.length && !this.table.tableData.body.length;
    },
    documentScroll() {
      return this.$store.state.documentScroll;
    },
    paginationCurrentPageActual() {
      return this.table?.savedTableData?.pagination
        && Object.keys(this.table.savedTableData.pagination).length
        ? this.table.savedTableData.pagination?.currentPage : this.table.pagination?.currentPage;
    },
    scrollLeftArrow() {
      return this.scrollLeft < 1;
    },
    scrollRightArrow() {
      return this.scrollRight < 1;
    },
  },
  watch: {
    table: {
      deep: true,
      handler(table) {
        /* added in M20-1484 temporary just for inactive application view start */
        // also remove table-content :class binding!
        if (table?.stateFilters?.choices) {
          this.progressState = '';
          table.stateFilters.choices.forEach((choice) => {
            if (choice.active) this.progressState = choice.value;
          });
        }

        // update table
        this.onTableUpdate();
      },
    },
    'table.tableData.body': {
      handler() {
        // if preventUpdateSelectedRows is not set
        // clear selected rows when data (body) updates
        if (this.preventUpdateSelectedRows === undefined) this.selectedRows = [];
      },
    },
    selectedRows(selectedRows) {
      if (this.preventUpdateSelectedRows) {
        this.preventUpdateSelectedRows = false;
        return;
      }
      this.$emit('selectedRows', selectedRows);
    },
    documentScroll: {
      deep: true,
      handler() {
        this.handleStickyHeader();
      },
    },
  },
  mounted() {
    this.mdtTableRef = this.$refs.mdtTable;
    this.updateSorting();
    this.onTableUpdate();
  },
  unmounted() {
    this.tableEl = null;
  },
  methods: {
    updateSorting() {
      // restore sortKey and sortOrder from saved table data
      let sortKey = JSON.parse(JSON.stringify(this.table?.savedTableData?.sortKey || ''));
      let sortOrder = '';
      if (sortKey && sortKey.charAt(0) !== '-') sortOrder = 'asc';
      else sortKey = sortKey.substring(1);
      this.sort.key = sortKey;
      this.sort.order = sortOrder;
    },
    onTableUpdate() {
      // Make certain table cells fixed
      this.$nextTick(() => {
        if (!this.mdtTableRef) return;
        const tableWrapper = this.mdtTableRef.querySelector('.table-wrapper');
        if (tableWrapper && !this.tableEl) this.tableEl = tableWrapper;
        this.onTableScroll();
      });
    },
    onTableScroll() {
      // Calculate fixed headers scroll
      this.setFixedHeadersScroll();
      // Sync scroll position with arrows
      this.scrollOnArrow();

      if (!this.tableEl) return;

      const activeFixedTd = this.tableEl.querySelectorAll('tbody > tr > td.fixed.mouseEnterActive');

      // If table is scrolled while row mouseenter is active
      if (activeFixedTd.length) {
        const vs = this.$refs['vue-scroll'];
        if (vs?.getPosition) {
          const lastIndex = activeFixedTd.length - 1;
          // Calculate scrollRight
          const { scrollLeft } = vs.getPosition();
          const scrollRight = this.tableEl.scrollWidth - vs.$el.scrollWidth - scrollLeft;

          for (let i = lastIndex; i >= 0; i--) {
            activeFixedTd[i].style.right = `${scrollRight}px`;
          }
        }
      }
    },
    rowMouseEnter(e) {
      const fixedTd = e.target.querySelectorAll('td.fixed:not(.selectColumn)');
      const vs = this.$refs['vue-scroll'];

      if (fixedTd.length && vs?.getPosition) {
        const lastIndex = fixedTd.length - 1;
        // Calculate scrollRight
        const { scrollLeft } = vs.getPosition();
        const scrollRight = this.tableEl.scrollWidth - vs.$el.scrollWidth - scrollLeft;

        for (let i = lastIndex; i >= 0; i--) {
          if (scrollRight > 0) {
            fixedTd[i].style.right = `${scrollRight}px`;
          }
          fixedTd[i].classList.add('mouseEnterActive');
        }
      }
    },
    rowMouseLeave(e) {
      const fixedTd = e.target.querySelectorAll('td.fixed:not(.selectColumn)');

      for (let i = 0; i < fixedTd.length; i++) {
        fixedTd[i].style.right = 'auto';
        fixedTd[i].classList.remove('mouseEnterActive');
      }
    },
    isRowSelected(row) {
      const id = row._id;
      return !!this.selectedRows.find((selected) => selected._id === id);
    },
    inactiveRow(row) {
      return {
        // eslint-disable-next-line no-underscore-dangle
        disabled: row?._detail_column?.disabled_row?.disabled,
        // eslint-disable-next-line no-underscore-dangle
        tooltip: row?._detail_column?.disabled_row?.tooltip || '',
      };
    },
    sortColumn(cell) {
      // If key is same, change order or reset
      if (cell.key === this.sort.key) {
        if (this.sort.order === 'desc') {
          this.sort.order = '';
          this.sort.key = '';
        } else {
          this.sort.order = 'desc';
        }
      } else {
        // If key is not same, just set default values
        this.sort.key = cell.key;
        this.sort.order = 'asc';
      }

      let key;
      if (this.sort.order) {
        key = this.sort.order === 'desc' ? `-${this.sort.key}` : this.sort.key;
      }
      this.$emit('sortColumn', key);
    },
    onPaginationCurrentPage(currentPage) {
      if (this.table.pagination?.currentPage === currentPage) return;
      const options = [{
        key: 'currentPage',
        value: currentPage,
      }];
      this.$emit('updatePagination', options);
    },
    onPaginationPageSize(size) {
      if (this.table.pagination.rowsPerPage === size) return;
      const options = [{
        key: 'rowsPerPage',
        value: size,
      }, {
        key: 'currentPage',
        value: 1,
      }];
      this.$emit('updatePagination', options);
    },
    saveReorderSettings(ordered) {
      const params = {
        key: this.table.settings.key,
        value: ordered,
      };
      this.$emit('saveReorder', params);
      this.isReorderOpen = false;
    },
    setFixedHeadersScroll() {
      // return if sticky headers disabled
      if (!this.stickyHeaders) return;
      // return if sticky headers are not visible
      if (this.table.loading || !this.stickyHeader) return;
      // return if table wrapper is not available
      const table = this.tableEl;
      if (!table) return;
      // get table headers and return if not available
      const tableHeaders = table.querySelector('.table-fixed-headers');
      if (!tableHeaders) return;

      // get vue-scroll and return if position not available
      const vs = this.$refs['vue-scroll'];
      if (!vs || !vs?.getPosition) return;

      // table and vue-scroll props
      const { scrollTop, scrollLeft } = vs.getPosition();

      // calculate table scroll using table wrapper
      const scroll = {
        top: scrollTop || 0,
        left: scrollLeft || 0,
        leftToRight: true,
      };
      scroll.leftToRight = this.tableScroll.left < scroll.left;
      this.tableScroll = scroll;

      // apply table scroll values to table headers
      tableHeaders.scrollLeft = this.tableScroll.left;
    },
    setStickyHeaderTableWidth(table) {
      const mainTableHeaders = table.querySelector('.main-table.headers');
      const mainTable = table.querySelector('.main-table:not(.headers)');
      if (!mainTableHeaders || !mainTable) return;
      // set table header width to sticky table header width
      mainTableHeaders.style.width = `${mainTable.clientWidth}px`;
      const thlFix = mainTableHeaders.querySelectorAll('thead > tr > th');
      const thl = mainTable.querySelectorAll('thead > tr > th');
      // set every sticky table header th width equal to table header th width
      for (let i = 0; i < thl.length; i++) {
        if (thlFix[i] && thl[i]) thlFix[i].style.width = `${thl[i].clientWidth}px`;
      }
    },
    handleStickyHeader() {
      // return if sticky headers disabled
      if (!this.stickyHeaders) return;
      const table = this.tableEl;
      if (!table) return;
      // get table rectangle top position
      const { top } = table.getBoundingClientRect();
      const oldStickyHeader = this.stickyHeader;
      // check if table is hidden in the DOM using v-show
      const visibleInTheDOM = this.mdtTableRef.offsetParent !== null;
      // set sticky header if table is visible in the DOM
      // and table headers are scrolled tup to main-navigation-top
      this.stickyHeader = visibleInTheDOM && top <= 64;
      // calculate table scroll and fixed header columns
      // if sticky header is show for the first time
      if (oldStickyHeader === false && this.stickyHeader === true) {
        this.$nextTick(() => {
          this.setStickyHeaderTableWidth(table);
          this.onTableScroll();
        });
      }
    },
    saveTableTd() {
      Object.keys(this.$refs).forEach((key) => {
        const ref = this.$refs[key];
        if (ref) {
          if (Array.isArray(ref)) {
            Object.keys(ref).forEach((k) => {
              const r = ref[k];
              if (r && r.saveTableTd) r.saveTableTd();
            });
          } else if (ref.saveTableTd) ref.saveTableTd();
        }
      });
      this.$emit('tableSaved');
    },
    updateSelectedRows(selectedRows, preventUpdateSelectedRows) {
      this.preventUpdateSelectedRows = preventUpdateSelectedRows;
      this.selectedRows = selectedRows;
    },
    scrollOnArrow(direction) {
      const vs = this.$refs['vue-scroll'];
      const { scrollLeft } = vs.getPosition();

      switch (direction) {
        case 'right':
          if (this.scrollRight > 0) {
            // eslint-disable-next-line
            this.scrollRight = this.tableEl.scrollWidth - vs.$el.scrollWidth - scrollLeft - TABLE_SCROLL_ARROW_LENGTH;
            this.scrollLeft = scrollLeft + TABLE_SCROLL_ARROW_LENGTH;
            vs.scrollBy({
              dx: TABLE_SCROLL_ARROW_LENGTH.toString(),
            });
          }
          break;
        case 'left':
          if (this.scrollLeft > 0) {
            // eslint-disable-next-line
            this.scrollRight = this.tableEl.scrollWidth - vs.$el.scrollWidth - scrollLeft + TABLE_SCROLL_ARROW_LENGTH;
            this.scrollLeft = scrollLeft - TABLE_SCROLL_ARROW_LENGTH;
            vs.scrollBy({
              dx: -TABLE_SCROLL_ARROW_LENGTH.toString(),
            });
          }
          break;
        default:
          // use this while scrolling with mouse wheel
          // use this while scrolling with scroll bar
          this.scrollLeft = scrollLeft;
          this.scrollRight = this.tableEl.scrollWidth - vs.$el.scrollWidth - scrollLeft;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-table:hover {
  .scroll-buttons-container,
  .scroll-buttons-container-sticky{
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }
}
.table-content {
  position: relative;

  /* added in M20-1484 temporary just for inactive application view start */
  // also remove table-content :class binding!
  ::v-deep {
    .main-table-th.deactivate_reason,
    .main-table-th.denial_reason,
    .main-table-td.deactivate_reason,
    .main-table-td.denial_reason {
      display: none;
    }
  }

  &.Applications-inactive,
  &.ApplicationsActive-inactive {
    ::v-deep {
      .main-table-th.deactivate_reason,
      .main-table-th.denial_reason,
      .main-table-td.deactivate_reason,
      .main-table-td.denial_reason {
        display: table-cell;
      }
    }
  }
  /* added temporary just for inactive application view end */

  .pagination {
    display: flex;
    padding-top: 8px;
  }
}

.table-wrapper {
  position: relative;
  padding-bottom: 16px; // because of horizontal (x) vuescroll
}

.scroll-buttons-container,
.scroll-buttons-container-sticky {
  position: absolute;
  top:67px;
  right:0;
  z-index: 12;
  opacity: 0;
  display: flex;
  justify-content: flex-end;
  background:$color-back-light;
  border-radius: 24px;
  i {
    opacity: 1;
    color: $color-text-secondary;
    font-size: 16px;
    padding:4px;
    cursor: pointer;
    &:hover:not(.scroll-arrow-disabled) {
      color: $color-text-primary;
    }
  }
  .scroll-arrow-disabled {
    opacity: 0.3;
  }
}
.scroll-buttons-container-sticky{
  position: fixed;
  top: 77px;
  right: 80px;
}

.table-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($color-back-primary-rgb, 0.6);
  z-index: 3;
}

.placeholders-list {
  width: 100%;
  overflow: hidden;

  .placeholders-list-item {
    margin: 20px 0;
    padding: 0 20px 10px 20px;
    border-bottom: 1px solid $border-color;
  }
}

.table-fixed-headers {
  position: fixed;
  box-shadow: 0px 4px 10px 0px #0000000d;
  overflow: hidden;
  z-index: 11;

  thead {
    position: relative;

    tr {
      th {
        display: inline-block;
        padding: 0 20px;
        background-color: $color-back-primary;
        line-height: 48px;
      }
    }
  }
}

.main-table {
  position: relative;
  width: 100%;
  border-collapse: separate;
  text-align: left;
  white-space: nowrap;
  border-spacing: 0 2px;

  tr {
    &:nth-child(2n + 1) td {
      background-color: $color-back-light;
    }

    &:nth-child(2n) td {
      background-color: $color-back-primary;
    }

    /* row border-radius start */
    td:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    td:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    /* row border-radius end */

    &.selected td {
      // style defined in _layout.scss
    }

    &.inactive {
      opacity: 0.3;
    }

    // select column to be shown above other columns
    .selectColumn {
      z-index: 1;
    }

    &:hover:not(.header):not(.row):not(.footer) {
      &:nth-child(2n + 1) td {
        background-color: $color-back-table-hover;
      }

      &:nth-child(2n) td {
        background-color: $color-back-table-hover-2;
      }

      &.selected td {
        // style defined in _layout.scss
      }

      ::v-deep {
        // hovering row highlight select column checkbox
        .selectColumn .mdt-checkbox:not(.checked) {
          label:before,
          label::after {
            border-color: $color-text-secondary;
          }
        }
      }
    }

    // tr type, used for cost planning table
    &.header {
      &:hover td {
        background-color: $color-back-table-hover;
      }

      td {
        background-color: $color-back-light;
      }
    }

    &.row {
      &:hover td {
        background-color: $color-back-table-hover-2;
      }

      td {
        background-color: $color-back-primary;
      }
    }

    &.footer {
      td {
        background-color: $color-back-hover;
        font-weight: bold;
      }
    }
  }

  th {
    min-width: 80px;
    height: 48px;
    color: $color-text-secondary;
    background-color: transparent;
  }

  td {
    min-height: 48px;
    height: 48px;

    &.fixed:not(.selectColumn) ::v-deep > * {
      opacity: 0;
    }

    &.mouseEnterActive.fixed ::v-deep > * {
      opacity: 1;
    }
  }

  th,
  td {
    padding: 8px 20px;
    font-size: 14px;

    &.selectColumn {
      position: sticky;
      left: 0;
    }
  }

  th.fixed {
    // white background color will cover header column titles
    background-color: $color-white;
    z-index: 2;
  }
}

.table-empty {
  flex-direction: column;
  padding: 40px 0;

  span {
    padding-top: 24px;
    color: $color-text-secondary;
    font-size: 14px;
  }
}

@media screen and (max-width: 859px) {
  .scroll-buttons-container {
    top: 99px;
  }
}
</style>
