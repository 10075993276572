/*
  NOTE:
    # Each route added here needs to be defined in routeMap (@/store/model/route-map/routeMap)
    # For each detail view route (or any child view that is not submenu point)
      we should add parent name that will expand parent menu where this detail view belongs:
      meta: {
        parentName: routeMap.objects.urlName,
      },
    # For each unprotected view route we should add unprotected meta unprotected: true
      that will be used in navigation guard to allow unprotected view:
      meta: { unprotected: true },
*/

import Vue from 'vue';
import VueRouter from 'vue-router';
import routeMap from '@/store/model/route-map/routeMap';

// Import layouts
import DashboardView from '@/layouts/DashboardView.vue';
import UnprotectedView from '@/layouts/UnprotectedView.vue';
import PublicView from '@/layouts/PublicView.vue';
import SettingsView from '@/layouts/SettingsView.vue';
import B2BView from '@/layouts/B2BView.vue';
import DmsView from '@/layouts/DmsView.vue';
import ApplicationForm from '@/layouts/ApplicationForm.vue';
import navigationGuards from './navigationGuards';
import { helpers } from '@/utility';

Vue.use(VueRouter);
// Set redirect page as default redirect url
const redirectUrl = routeMap.redirect.urlName;

// handle initial route for project settings software interfaces
const isInvest = helpers.checkProjectTypeFromUrl('invest');

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '*',
      redirect: {
        name: redirectUrl,
      },
    },
    {
      path: routeMap.redirect.url,
      component: DashboardView,
      children: [
        {
          path: '',
          name: routeMap.redirect.urlName,
          component: () => import(/* webpackChunkName: "redirect-page" */ '@/pages/Redirect.vue'),
        },
        {
          path: routeMap.home.url,
          name: routeMap.home.urlName,
          component: () => import(/* webpackChunkName: "home-page" */ '@/pages/Home.vue'),
        },
        {
          path: routeMap.applications.url,
          name: routeMap.applications.urlName,
          component: () => import(/* webpackChunkName: "applications-page" */ '@/pages/Applications.vue'),
        },
        {
          path: routeMap.applicationDetails.url,
          name: routeMap.applicationDetails.urlName,
          component: () => import(/* webpackChunkName: "applications-details" */ '@/components/applications/ApplicationsDetail.vue'),
          meta: {
            parentName: routeMap.applications.urlName,
          },
        },
        {
          path: routeMap.objects.url,
          name: routeMap.objects.urlName,
          redirect: { name: routeMap.objects.children.apartments.urlName },
          component: () => import(/* webpackChunkName: "objects-page" */ '@/pages/Objects.vue'),
          children: [
            {
              path: routeMap.objects.children.apartments.url,
              name: routeMap.objects.children.apartments.urlName,
            },
            {
              path: routeMap.objects.children.parkingSpace.url,
              name: routeMap.objects.children.parkingSpace.urlName,
            },
            {
              path: routeMap.objects.children.outbuildings.url,
              name: routeMap.objects.children.outbuildings.urlName,
            },
            {
              path: routeMap.objects.children.business.url,
              name: routeMap.objects.children.business.urlName,
            },
            {
              path: routeMap.objects.children.buildings.url,
              name: routeMap.objects.children.buildings.urlName,
            },
            {
              path: routeMap.objects.children.plots.url,
              name: routeMap.objects.children.plots.urlName,
            },
          ],
        },
        {
          path: routeMap.objectDetails.url,
          name: routeMap.objectDetails.urlName,
          component: () => import(/* webpackChunkName: "apartment-details" */ '@/components/apartments/ApartmentDetails.vue'),
          meta: {
            parentName: routeMap.objects.urlName,
          },
        },
        {
          path: routeMap.tenant.url,
          name: routeMap.tenant.urlName,
          component: () => import(/* webpackChunkName: "tenants-page" */ '@/pages/Tenants.vue'),
        },
        {
          path: routeMap.tenantDetails.url,
          name: routeMap.tenantDetails.urlName,
          component: () => import(/* webpackChunkName: "tenant-details" */ '@/components/tenants/TenantDetails.vue'),
          meta: {
            parentName: routeMap.tenant.urlName,
          },
        },
        {
          path: routeMap.reporting.url,
          name: routeMap.reporting.urlName,
          redirect: { name: routeMap.reporting.children.pdfReporting.urlName },
          component: () => import(/* webpackChunkName: "reporting-page" */ '@/pages/Reporting.vue'),
          children: [
            {
              path: routeMap.reporting.children.pdfReporting.url,
              name: routeMap.reporting.children.pdfReporting.urlName,
              component: () => import(/* webpackChunkName: "reporting-pdf-page" */ '@/components/reporting/ReportingPdf.vue'),
            },
          ],
        },
        {
          path: routeMap.interestedPeople.url,
          name: routeMap.interestedPeople.urlName,
          component: () => import(/* webpackChunkName: "interested-people-page" */ '@/pages/InterestedPeople.vue'),
        },
        {
          path: routeMap.interestedPeopleB2B.url,
          name: routeMap.interestedPeopleB2B.urlName,
          component: () => import(/* webpackChunkName: "interested-people-b2b-page" */ '@/pages/InterestedPeopleB2B.vue'),
        },
        {
          path: routeMap.budget.url,
          name: routeMap.budget.urlName,
          redirect: { name: routeMap.budget.children.overview.urlName },
          component: () => import(/* webpackChunkName: "budget-page" */ '@/pages/Budget.vue'),
          children: [
            {
              path: routeMap.budget.children.overview.url,
              name: routeMap.budget.children.overview.urlName,
              component: () => import(/* webpackChunkName: "budget-overview-page" */ '@/components/budget/BudgetOverview.vue'),
            },
            {
              path: routeMap.budget.children.costPlanning.url,
              name: routeMap.budget.children.costPlanning.urlName,
              component: () => import(/* webpackChunkName: "budget-cost-plan-page" */ '@/components/budget/BudgetCostPlan.vue'),
            },
            {
              path: routeMap.budget.children.feeCalculation.url,
              name: routeMap.budget.children.feeCalculation.urlName,
              component: () => import(/* webpackChunkName: "budget-fee-calculation-page" */ '@/components/budget/BudgetFeeCalculation.vue'),
            },
          ],
        },
        {
          path: routeMap.communication.url,
          name: routeMap.communication.urlName,
          component: () => import(/* webpackChunkName: "communication-page" */ '@/pages/Communication.vue'),
        },
        {
          path: routeMap.profileSettings.url,
          name: routeMap.profileSettings.urlName,
          component: () => import(/* webpackChunkName: "profile-settings" */ '@/pages/ProfileSettings.vue'),
          redirect: {
            name: routeMap.profileSettings.children.account.urlName,
          },
          children: [
            {
              path: routeMap.profileSettings.children.account.url,
              name: routeMap.profileSettings.children.account.urlName,
              component: () => import(/* webpackChunkName: "profile-settings-account" */ '@/components/profile-settings/ProfileSettingsAccount.vue'),
            },
            {
              path: routeMap.profileSettings.children.notifications.url,
              name: routeMap.profileSettings.children.notifications.urlName,
              component: () => import(/* webpackChunkName: "profile-settings-notifications" */ '@/components/profile-settings/ProfileSettingsNotifications.vue'),
            },
          ],
        },
        {
          path: routeMap.waitlist.url,
          name: routeMap.waitlist.urlName,
          component: () => import(/* webpackChunkName: "waitlist-page" */ '@/pages/Waitlist.vue'),
        },
        {
          path: routeMap.waitlistDetails.url,
          name: routeMap.waitlistDetails.urlName,
          component: () => import(/* webpackChunkName: "waitlist-details" */ '@/components/waitlist/WaitlistDetails.vue'),
          meta: {
            parentName: routeMap.waitlist.urlName,
          },
        },
        {
          path: routeMap.viewingAppointments.url,
          name: routeMap.viewingAppointments.urlName,
          redirect: {
            name: routeMap.viewingAppointments.children.booked.urlName,
          },
          component: () => import(/* webpackChunkName: "viewing-appointments-page" */ '@/pages/ViewingAppointments.vue'),
          children: [
            {
              path: routeMap.viewingAppointments.children.booked.url,
              name: routeMap.viewingAppointments.children.booked.urlName,
              component: () => import(/* webpackChunkName: "appointments-booked" */ '@/components/viewing-appointments/AppointmentsBooked.vue'),
            },
            {
              path: routeMap.viewingAppointments.children.withoutRegistrations.url,
              name: routeMap.viewingAppointments.children.withoutRegistrations.urlName,
              component: () => import(/* webpackChunkName: "appointments-without-registrations" */ '@/components/viewing-appointments/AppointmentsWithoutRegistrations.vue'),
            },
          ],
        },
        {
          path: routeMap.projects.url,
          name: routeMap.projects.urlName,
          component: () => import(/* webpackChunkName: "projects-page" */ '@/pages/Projects.vue'),
        },
        {
          path: routeMap.projectDetails.url,
          name: routeMap.projectDetails.urlName,
          component: () => import(/* webpackChunkName: "project-details" */ '@/components/projects/ProjectDetails.vue'),
          meta: {
            parentName: routeMap.projects.urlName,
          },
        },
        {
          path: routeMap.investors.url,
          name: routeMap.investors.urlName,
          component: () => import(/* webpackChunkName: "investors-page" */ '@/pages/Investors.vue'),
        },
        {
          path: routeMap.investorDetails.url,
          name: routeMap.investorDetails.urlName,
          component: () => import(/* webpackChunkName: "investor-details" */ '@/components/investors/InvestorDetails.vue'),
          meta: {
            parentName: routeMap.investors.urlName,
          },
        },
        {
          path: routeMap.createContract.url,
          name: routeMap.createContract.urlName,
          component: () => import(/* webpackChunkName: "create-contract-page" */ '@/components/tenants/digital-contract/create-contract/CreateContract.vue'),
        },
        {
          path: routeMap.analytics.url,
          name: routeMap.analytics.urlName,
          component: () => import(/* webpackChunkName: "analytics-page" */ '@/pages/Analytics.vue'),
        },
        {
          path: routeMap.analyticsDetails.url,
          name: routeMap.analyticsDetails.urlName,
          component: () => import(/* webpackChunkName: "analytics-details" */ '@/components/analytics/AnalyticsDetails.vue'),
        },
        {
          path: routeMap.chartsTesting.url,
          name: routeMap.chartsTesting.urlName,
          component: () => import(/* webpackChunkName: "charts-testing-page" */ '@/components/analytics/ChartsTestingPage.vue'),
        },
      ],
    },
    {
      path: '/',
      name: 'UnprotectedView',
      meta: { unprotected: true }, // Pass to recognize unprotected views
      component: UnprotectedView,
      children: [
        {
          path: '/login',
          name: 'Login',
          meta: { unprotected: true },
          component: () => import(/* webpackChunkName: "login" */ '@/pages/Login.vue'),
        },
        {
          path: '/403',
          name: 'AccessDenied',
          meta: { unprotected: true },
          component: () => import(/* webpackChunkName: "access-denied" */ '@/components/unprotected-view/403.vue'),
        },
        {
          path: '/404',
          name: 'NotFound',
          meta: { unprotected: true },
          component: () => import(/* webpackChunkName: "not-found" */ '@/components/unprotected-view/404.vue'),
        },
        {
          path: '/public/404',
          name: 'NotFoundPublic',
          meta: { unprotected: true },
          component: () => import(/* webpackChunkName: "not-found-public" */ '@/components/unprotected-view/404Public.vue'),
        },
        {
          path: '/dms-registration/:dmsSlug/:userSlug/:verificationCode?',
          name: 'DmsRegistration',
          meta: { unprotected: true, dms: true },
          component: () => import(/* webpackChunkName: "dms-registration" */ '@/components/unprotected-view/dms/DmsRegistration.vue'),
        },
        {
          path: '/dms-login/:dmsSlug',
          name: 'DmsLogin',
          meta: { unprotected: true, dms: true },
          component: () => import(/* webpackChunkName: "dms-login" */ '@/components/unprotected-view/dms/login/DmsLogin.vue'),
        },
        {
          path: '/dms-password-reset/:dmsSlug/:userSlug/:token',
          name: 'DmsPasswordReset',
          meta: { unprotected: true, dms: true },
          component: () => import(/* webpackChunkName: "dms-password-reset" */ '@/components/unprotected-view/dms/DmsPasswordReset.vue'),
        },
        {
          path: '/dms-phone-number-auth/:dmsSlug/:userSlug/:phoneNumber',
          name: 'DmsPhoneNumberAuth',
          meta: { unprotected: true, dms: true },
          component: () => import(/* webpackChunkName: "dms-phone-number-auth" */ '@/components/unprotected-view/dms/DmsPhoneNumberAuth.vue'),
        },
        {
          path: '/dms-change-phone-number/:dmsSlug/:userSlug',
          name: 'DmsChangePhoneNumber',
          meta: { unprotected: true, dms: true },
          component: () => import(/* webpackChunkName: "dms-change-phone-number" */ '@/components/unprotected-view/dms/DmsChangePhoneNumber.vue'),
        },
        {
          path: 'action/:id/offer-form',
          name: 'OfferForm',
          meta: { unprotected: true, changeFavicon: true },
          component: () => import(/* webpackChunkName: "offer-form" */ '@/components/unprotected-view/OfferForm.vue'),
        },
        {
          path: 'action/:id/reference-form',
          name: 'ReferenceForm',
          meta: { unprotected: true, changeFavicon: true },
          component: () => import(/* webpackChunkName: "reference-form" */ '@/components/unprotected-view/ReferenceForm.vue'),
        },
        {
          path: 'action/:uuid/:token/documents-upload-form',
          name: 'AdultDocumentsUploadForm',
          meta: { unprotected: true, changeFavicon: true },
          component: () => import(/* webpackChunkName: "adult-documents-upload-form" */ '@/components/unprotected-view/AdultDocumentsUploadForm.vue'),
        },
        {
          path: 'account/:id/password-reset-form',
          name: 'PasswordResetForm',
          meta: { unprotected: true },
          component: () => import(/* webpackChunkName: "password-reset-form" */ '@/components/unprotected-view/PasswordResetForm.vue'),
        },
        {
          path: 'action/:uuid/:token/accounting-confirmation-form',
          name: 'BookkeepingConfirmationForm',
          meta: { unprotected: true },
          component: () => import(/* webpackChunkName: "bookkeeping-confirmation-form" */ '@/components/unprotected-view/BookkeepingConfirmationForm.vue'),
        },
        {
          path: 'action/:uuid/:token/waitlist-criteria-form',
          name: 'WaitlistCriteriaForm',
          meta: { unprotected: true, changeFavicon: true },
          component: () => import(/* webpackChunkName: "waitlist-criteria-form" */ '@/components/unprotected-view/waitlist/WaitlistCriteriaForm.vue'),
        },
        {
          path: 'action/:uuid/:token/waitlist-criteria-preview',
          name: 'WaitlistCriteriaPreview',
          meta: { unprotected: true, changeFavicon: true },
          component: () => import(/* webpackChunkName: "waitlist-criteria-preview" */ '@/components/unprotected-view/waitlist/WaitlistCriteriaForm.vue'),
        },
        {
          path: 'action/:uuid/:token/waitlist-proposal-view',
          name: 'WaitlistProposal',
          meta: { unprotected: true, changeFavicon: true },
          component: () => import(/* webpackChunkName: "waitlist-proposal" */ '@/components/unprotected-view/waitlist/WaitlistProposal.vue'),
        },
        {
          path: 'action/:uuid/:token/waitlist-public-decline',
          name: 'WaitlistDeactivate',
          meta: { unprotected: true, changeFavicon: true },
          component: () => import(/* webpackChunkName: "waitlist-deactivate" */ '@/components/unprotected-view/waitlist/WaitlistDeactivate.vue'),
        }, {
          path: 'action/:interestedPersonSlug/appointments-booking-general',
          name: 'AppointmentsBookingGeneral',
          meta: { unprotected: true, viewing: true, changeFavicon: true },
          component: () => import(/* webpackChunkName: "appointments-booking-general" */ '@/components/unprotected-view/viewing-appointments/AppointmentsBookingGeneral.vue'),
        },
        {
          path: 'action/appointments-booking-general-with-contact',
          name: 'AppointmentsBookingGeneralWithContact',
          meta: { unprotected: true, viewing: true, changeFavicon: true },
          component: () => import(/* webpackChunkName: "appointments-booking-general-with-contact" */ '@/components/unprotected-view/viewing-appointments/AppointmentsBookingGeneralWithContact.vue'),
        },
        {
          path: 'action/:objectSlug/:interestedPersonSlug/appointments-booking-object',
          name: 'AppointmentsBookingObject',
          meta: { unprotected: true, viewing: true, changeFavicon: true },
          component: () => import(/* webpackChunkName: "appointments-booking-object" */ '@/components/unprotected-view/viewing-appointments/AppointmentsBookingObject.vue'),
        },
        {
          path: 'action/:objectSlug/appointments-booking-object-with-contact',
          name: 'AppointmentsBookingObjectWithContact',
          meta: { unprotected: true, viewing: true, changeFavicon: true },
          component: () => import(/* webpackChunkName: "appointments-booking-object-with-contact" */ '@/components/unprotected-view/viewing-appointments/AppointmentsBookingObjectWithContact.vue'),
        },
        {
          path: 'action/:appointmentSlug/:interestedPersonSlug/appointment-cancel-booking',
          name: 'AppointmentCancelBooking',
          meta: { unprotected: true, viewing: true, changeFavicon: true },
          component: () => import(/* webpackChunkName: "appointment-cancel-booking" */ '@/components/unprotected-view/viewing-appointments/AppointmentCancelBooking.vue'),
        },
        {
          path: 'action/:appointmentSlug/appointment-edit-participants',
          name: 'AppointmentEditParticipants',
          meta: { unprotected: true, viewing: true, changeFavicon: true },
          component: () => import(/* webpackChunkName: "appointment-edit-participants" */ '@/components/unprotected-view/viewing-appointments/AppointmentEditParticipants.vue'),
        },
        {
          path: 'action/:slug/:token/investor-waitlist-request-edit',
          name: 'InvestorWaitlistRequestEdit',
          meta: { unprotected: true, changeFavicon: true },
          component: () => import(/* webpackChunkName: "investor-waitlist-request-edit" */ '@/components/unprotected-view/InvestorWaitlistRequestEdit.vue'),
        },
        {
          path: 'action/:slug/:token/investor-waitlist-request-preview',
          name: 'InvestorWaitlistRequestPreview',
          meta: { unprotected: true, changeFavicon: true },
          component: () => import(/* webpackChunkName: "investor-waitlist-request-preview" */ '@/components/unprotected-view/InvestorWaitlistRequestEdit.vue'),
        },
        {
          path: '/project-teaser/:projectSlug',
          name: 'B2BProjectTeaser',
          meta: { unprotected: true, b2b: true, changeFavicon: true },
          component: () => import(/* webpackChunkName: "project-teaser" */ '@/components/unprotected-view/b2b/B2BProjectTeaser.vue'),
        },
        {
          path: '/b2b-entry/:projectSlug',
          name: 'B2BEntry',
          meta: { unprotected: true, b2b: true, changeFavicon: true },
          component: () => import(/* webpackChunkName: "b2b-entry" */ '@/components/unprotected-view/b2b/entry/B2BEntry.vue'),
        },
        {
          path: '/b2b-password-reset/:projectSlug/:userSlug/:token',
          name: 'B2BPasswordReset',
          meta: { unprotected: true, b2b: true, changeFavicon: true },
          component: () => import(/* webpackChunkName: "b2b-password-reset" */ '@/components/unprotected-view/b2b/B2BPasswordReset.vue'),
        },
        {
          path: '/b2b-email-verify/:projectSlug/:userSlug/:token',
          name: 'B2BEmailVerify',
          meta: { unprotected: true, b2b: true, changeFavicon: true },
          component: () => import(/* webpackChunkName: "b2b-email-verify" */ '@/components/unprotected-view/b2b/B2BEmailVerify.vue'),
        },
        {
          path: '/b2b-phone-number-auth/:projectSlug/:userSlug/:phoneNumber',
          name: 'B2BPhoneNumberAuth',
          meta: { unprotected: true, b2b: true, changeFavicon: true },
          component: () => import(/* webpackChunkName: "b2b-phone-number-auth" */ '@/components/unprotected-view/b2b/B2BPhoneNumberAuth.vue'),
        },
        {
          path: '/b2b-change-phone-number/:projectSlug/:userSlug',
          name: 'B2BChangePhoneNumber',
          meta: { unprotected: true, b2b: true, changeFavicon: true },
          component: () => import(/* webpackChunkName: "b2b-change-phone-number" */ '@/components/unprotected-view/b2b/B2BChangePhoneNumber.vue'),
        },
        {
          path: '/action/:projectSlug/:projectToken/investor-opt-out',
          name: 'InvestorUnsubscribe',
          meta: { unprotected: true, changeFavicon: true },
          component: () => import(/* webpackChunkName: "investor-unsubscribe" */ '@/components/unprotected-view/InvestorUnsubscribe.vue'),
        },
      ],
    },
    {
      path: '/',
      name: 'PublicView',
      component: PublicView,
      children: [
        {
          path: 'public-object-list',
          name: 'PublicObjectList',
          meta: { unprotected: true },
          component: () => import(/* webpackChunkName: "public-object-list" */ '@/components/public-view/PublicObjectList.vue'),
        },
        {
          path: 'public-object-details/:id',
          name: 'PublicObjectDetails',
          meta: { unprotected: true },
          component: () => import(/* webpackChunkName: "public-object-details" */ '@/components/public-view/public-object-details/PublicObjectDetails.vue'),
        },
        {
          path: 'version',
          name: 'ApplicationVersion',
          meta: { unprotected: true },
          component: () => import(/* webpackChunkName: "application-version" */ '@/components/public-view/ApplicationVersion.vue'),
        },
      ],
    },
    {
      path: '/',
      component: SettingsView,
      children: [
        {
          path: 'project-settings',
          name: 'ProjectGlobalSettings',
          redirect: {
            name: routeMap.projectSettings.children.general.urlName,
          },
          component: () => import(/* webpackChunkName: "project-settings" */ '@/components/project-settings/ProjectSettings.vue'),
          children: [
            // Project
            {
              path: routeMap.projectSettings.url,
              name: routeMap.projectSettings.urlName,
              redirect: {
                name: routeMap.projectSettings.children.general.urlName,
              },
              component: () => import(/* webpackChunkName: "project-settings-project" */ '@/components/project-settings/project/ProjectSettingsProject.vue'),
              children: [
                {
                  path: routeMap.projectSettings.children.general.url,
                  name: routeMap.projectSettings.children.general.urlName,
                  component: () => import(/* webpackChunkName: "project-settings-general" */ '@/components/project-settings/project/ProjectSettingsProjectGeneral.vue'),
                },
                {
                  path: routeMap.projectSettings.children.contact.url,
                  name: routeMap.projectSettings.children.contact.urlName,
                  component: () => import(/* webpackChunkName: "project-settings-contact" */ '@/components/project-settings/project/ProjectSettingsProjectContact.vue'),
                },
              ],
            },
            // Documents
            {
              path: routeMap.documentSettings.url,
              name: routeMap.documentSettings.urlName,
              redirect: {
                name: routeMap.documentSettings.children.flyer.urlName,
              },
              component: () => import(/* webpackChunkName: "project-settings-documents" */ '@/components/project-settings/documents/ProjectSettingsDocuments.vue'),
              children: [
                {
                  path: routeMap.documentSettings.children.flyer.url,
                  name: routeMap.documentSettings.children.flyer.urlName,
                  component: () => import(/* webpackChunkName: "project-settings-flyer" */ '@/components/project-settings/documents/ProjectSettingsDocumentsFlyer.vue'),
                },
                {
                  path: routeMap.documentSettings.children.businessCard.url,
                  name: routeMap.documentSettings.children.businessCard.urlName,
                  component: () => import(/* webpackChunkName: "project-settings-business-card" */ '@/components/project-settings/documents/ProjectSettingsDocumentsBusinessCard.vue'),
                },
              ],
            },
            // Process Settings
            {
              path: routeMap.processSettings.url,
              name: routeMap.processSettings.urlName,
              component: () => import(/* webpackChunkName: "project-settings-process" */ '@/components/project-settings/process-settings/ProjectSettingsProcess.vue'),
            },
            // User Management
            {
              path: routeMap.userManagement.url,
              name: routeMap.userManagement.urlName,
              redirect: {
                name: routeMap.userManagement.children.users.urlName,
              },
              component: () => import(/* webpackChunkName: "project-settings-user-management" */ '@/components/project-settings/user-management/ProjectSettingsUserManagement.vue'),
              children: [
                {
                  path: routeMap.userManagement.children.users.url,
                  name: routeMap.userManagement.children.users.urlName,
                  component: () => import(/* webpackChunkName: "project-settings-users" */ '@/components/project-settings/user-management/ProjectSettingsUserManagementUsers.vue'),
                },
                {
                  path: routeMap.userManagement.children.teams.url,
                  name: routeMap.userManagement.children.teams.urlName,
                  component: () => import(/* webpackChunkName: "project-settings-teams" */ '@/components/project-settings/user-management/ProjectSettingsUserManagementTeams.vue'),
                },
                {
                  path: routeMap.userManagement.children.roles.url,
                  name: routeMap.userManagement.children.roles.urlName,
                  component: () => import(/* webpackChunkName: "project-settings-roles" */ '@/components/project-settings/user-management/ProjectSettingsUserManagementRoles.vue'),
                },
                {
                  path: routeMap.userManagement.children.owners.url,
                  name: routeMap.userManagement.children.owners.urlName,
                  component: () => import(/* webpackChunkName: "project-settings-owners" */ '@/components/project-settings/user-management/owners/ProjectSettingsUserManagementOwners.vue'),
                },
              ],
            },
            // Communication
            {
              path: routeMap.communicationSettings.url,
              name: routeMap.communicationSettings.urlName,
              redirect: {
                name: routeMap.communicationSettings.children.emailSignature.urlName,
              },
              component: () => import(/* webpackChunkName: "project-settings-communication" */ '@/components/project-settings/communication/ProjectSettingsCommunication.vue'),
              children: [
                {
                  path: routeMap.communicationSettings.children.emailSignature.url,
                  name: routeMap.communicationSettings.children.emailSignature.urlName,
                  component: () => import(/* webpackChunkName: "project-settings-email-signature" */ '@/components/project-settings/communication/ProjectSettingsCommunicationEmailSignature.vue'),
                },
                {
                  path: routeMap.communicationSettings.children.emailTemplates.url,
                  name: routeMap.communicationSettings.children.emailTemplates.urlName,
                  component: () => import(/* webpackChunkName: "project-settings-email-templates" */ '@/components/project-settings/communication/ProjectSettingsCommunicationEmailTemplates.vue'),
                },
              ],
            },
            // Reservation Settings
            {
              path: routeMap.reservationSettings.url,
              name: routeMap.reservationSettings.urlName,
              component: () => import(/* webpackChunkName: "project-settings-reservation" */ '@/components/project-settings/reservation/ProjectSettingsReservation.vue'),
            },
            // Website
            {
              path: routeMap.websiteSettings.url,
              name: routeMap.websiteSettings.urlName,
              component: () => import(/* webpackChunkName: "project-settings-website" */ '@/components/project-settings/website/ProjectSettingsWebsite.vue'),
              children: [
                {
                  path: routeMap.websiteSettings.children.pageBuilder.url,
                  name: routeMap.websiteSettings.children.pageBuilder.urlName,
                  component: () => import(/* webpackChunkName: "project-settings-page-builder" */ '@/components/project-settings/website/ProjectSettingsWebsitePageBuilder.vue'),
                },
                {
                  path: routeMap.websiteSettings.children.iframe.url,
                  name: routeMap.websiteSettings.children.iframe.urlName,
                  component: () => import(/* webpackChunkName: "project-settings-iframe" */ '@/components/project-settings/website/ProjectSettingsWebsiteIframe.vue'),
                },
              ],
            },
            // Software Interfaces
            {
              path: routeMap.softwareInterfaces.url,
              name: routeMap.softwareInterfaces.urlName,
              redirect: {
                name: routeMap.softwareInterfaces.children[isInvest ? 'authentication' : 'rentalPlatforms'].urlName,
              },
              component: () => import(/* webpackChunkName: "project-settings-software-interfaces" */ '@/components/project-settings/software-interfaces/ProjectSettingsSoftwareInterfaces.vue'),
              children: [
                {
                  path: routeMap.softwareInterfaces.children.authentication.url,
                  name: routeMap.softwareInterfaces.children.authentication.urlName,
                  component: () => import(/* webpackChunkName: "project-settings-authentication" */ '@/components/project-settings/software-interfaces/ProjectSettingsSoftwareInterfacesAuthentication.vue'),
                },
                {
                  path: routeMap.softwareInterfaces.children.rentalPlatforms.url,
                  name: routeMap.softwareInterfaces.children.rentalPlatforms.urlName,
                  component: () => import(/* webpackChunkName: "project-settings-rental-platforms" */ '@/components/project-settings/software-interfaces/ProjectSettingsSoftwareInterfacesRentalPlatforms.vue'),
                },
                {
                  path: routeMap.softwareInterfaces.children.creditCheck.url,
                  name: routeMap.softwareInterfaces.children.creditCheck.urlName,
                  component: () => import(/* webpackChunkName: "project-settings-credit-check" */ '@/components/project-settings/software-interfaces/ProjectSettingsSoftwareInterfacesCreditCheck.vue'),
                },
                {
                  path: routeMap.softwareInterfaces.children.abaImmo.url,
                  name: routeMap.softwareInterfaces.children.abaImmo.urlName,
                  component: () => import(/* webpackChunkName: "project-settings-aba-immo" */ '@/components/project-settings/software-interfaces/ProjectSettingsSoftwareInterfacesAbaImmo.vue'),
                },
              ],
            },
            {
              path: routeMap.digitalSignatureSettings.url,
              name: routeMap.digitalSignatureSettings.urlName,
              component: () => import(/* webpackChunkName: "project-settings-digital-signature" */ '@/components/project-settings/digital-signature/ProjectSettingsDigitalSignature.vue'),
            },
            {
              path: routeMap.dmsSettings.url,
              name: routeMap.dmsSettings.urlName,
              redirect: {
                name: routeMap.dmsSettings.children.categories.urlName,
              },
              component: () => import(/* webpackChunkName: "project-settings-dms" */ '@/components/project-settings/dms/ProjectSettingsDms.vue'),
              children: [
                {
                  path: routeMap.dmsSettings.children.categories.url,
                  name: routeMap.dmsSettings.children.categories.urlName,
                  component: () => import(/* webpackChunkName: "project-settings-dms-categories" */ '@/components/project-settings/dms/categories/ProjectSettingsDmsCategories.vue'),
                },
                {
                  path: routeMap.dmsSettings.children.roles.url,
                  name: routeMap.dmsSettings.children.roles.urlName,
                  component: () => import(/* webpackChunkName: "project-settings-dms-roles" */ '@/components/project-settings/dms/roles/ProjectSettingsDmsRoles.vue'),
                },
              ],
            },
            // Form settings
            {
              path: routeMap.formSettings.url,
              name: routeMap.formSettings.urlName,
              redirect: {
                name: routeMap.formSettings.children.general.urlName,
              },
              component: () => import(/* webpackChunkName: "project-settings-form-settings" */ '@/components/project-settings/form-settings/ProjectSettingsFormSettings.vue'),
              children: [
                {
                  path: routeMap.formSettings.children.forms.url,
                  name: routeMap.formSettings.children.forms.urlName,
                  component: () => import(/* webpackChunkName: "project-settings-forms" */ '@/components/project-settings/form-settings/ProjectSettingsProjectForms.vue'),
                },
                {
                  path: routeMap.formSettings.children.general.url,
                  name: routeMap.formSettings.children.general.urlName,
                  component: () => import(/* webpackChunkName: "project-settings-form.general" */ '@/components/project-settings/form-settings/ProjectSettingsProjectFormGeneral.vue'),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/',
      name: 'B2BView',
      component: B2BView,
      children: [
        {
          path: routeMap.b2bProjectNda.url,
          name: routeMap.b2bProjectNda.urlName,
          meta: { b2b: true, changeFavicon: true },
          component: () => import(/* webpackChunkName: "b2b-project-nda" */ '@/components/b2b/B2BProjectNda.vue'),
        },
        {
          path: routeMap.b2bProjectMemorandum.url,
          name: routeMap.b2bProjectMemorandum.urlName,
          meta: { b2b: true, changeFavicon: true },
          component: () => import(/* webpackChunkName: "b2b-project-memorandum" */ '@/components/b2b/project-memorandum/B2BProjectMemorandum.vue'),
        },
        {
          path: routeMap.b2bProjectMemorandumPitch.url,
          name: routeMap.b2bProjectMemorandumPitch.urlName,
          meta: { b2b: true, changeFavicon: true },
          component: () => import(/* webpackChunkName: "b2b-project-memorandum-pitch" */ '@/components/b2b/project-memorandum/B2BProjectMemorandumPitch'),
        },
      ],
    },
    {
      path: '/',
      name: 'DmsView',
      component: DmsView,
      children: [
        {
          path: routeMap.dms.url,
          name: routeMap.dms.urlName,
          component: () => import(/* webpackChunkName: "dms" */ '@/components/dms/Dms.vue'),
          meta: { dms: true },
        },
      ],
    },
    {
      path: '/',
      name: 'ApplicationForm',
      component: ApplicationForm,
      children: [
        {
          path: routeMap.applicationForm.children.landingPage.url,
          name: routeMap.applicationForm.children.landingPage.urlName,
          meta: routeMap.applicationForm.children.landingPage.meta,
          component: () => import(/* webpackChunkName: "application-form-welcome" */ '@/components/application-form/ApplicationFormWelcomePage.vue'),
        },
        {
          path: routeMap.applicationForm.children.newForm.url,
          name: routeMap.applicationForm.children.newForm.urlName,
          meta: routeMap.applicationForm.children.newForm.meta,
          component: () => import(/* webpackChunkName: "application-form-new" */ '@/components/application-form/ApplicationFormContainer.vue'),
        },
        {
          path: routeMap.applicationFormUpdate.url,
          name: routeMap.applicationFormUpdate.urlName,
          meta: routeMap.applicationFormUpdate.meta,
          component: () => import(/* webpackChunkName: "application-form-update" */ '@/components/application-form/ApplicationFormContainer.vue'),
        },
        {
          path: routeMap.applicationFormCreateAdult.url,
          name: routeMap.applicationFormCreateAdult.urlName,
          meta: routeMap.applicationFormCreateAdult.meta,
          component: () => import(/* webpackChunkName: "application-form-adult-create" */ '@/components/application-form/CreateAdultExternal.vue'),
        },
      ],
    },
  ],
});

// Redirect user to login page if not authenticated
router.beforeEach(navigationGuards.isUserAuthenticated);

export default router;
